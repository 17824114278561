// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

@import "../../node_modules/vuetify/dist/vuetify.min.css";

@import "../../node_modules/vue-tour/dist/vue-tour.css";

html,
body,
#app,
.v-application--wrap,
#map {
    background-color: #fff;
    color: #636b6f;
    font-family: "Nunito", sans-serif;
    font-weight: 200;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#map {
    z-index: 5;
}

h2 {
    margin: 1.25rem 0;
    font-weight: 800;
}

p {
    line-height: 1.85 !important;
    white-space: break-spaces;
    letter-spacing: 0.034286em;
    font-family: Roboto, Arial, sans-serif;
    font-size: 0.975rem;
    font-weight: 500;
    padding: 12px 0px;
    page-break-inside: avoid;
    opacity: 0.75;
}

.full-height {
    height: 100vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    display: none;
}

@media (min-width: 768px) {
    .top-right {
        position: absolute;
        top: 18px;
        right: 18px;
        display: block;
    }
}

.top-right.links {
    background: white;
    display: block;
    align-items: center;
    z-index: 10;
    border-radius: 0.55rem;
    image-rendering: -webkit-optimize-contrast;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}

.top-right.links .logo {
    border-radius: 0.55rem;
}

.top-right.links .v-btn {
    margin-right: 26px;
}

.top-right.links .table {
    background-color: rgba(255, 255, 255, 0.75) !important;
    backdrop-filter: blur(20px) saturate(180%);
    border-radius: 0.55rem;
    padding: 0.5rem;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}

.top-right.links .table div {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0.5rem;
}

.top-right.links .table p {
    margin: 0;
    padding: 0;
    line-height: 0 !important;
}

.top-right.links .table img {
    width: 25px;
    height: 25px;
    border-radius: 350px;
    margin-right: 0.5rem;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}

.content {
    text-align: center;
}

.title {
    font-size: 84px;
}

.links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.m-b-md {
    margin-bottom: 30px;
}

.leaflet-bar {
    box-shadow: none !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none !important;
}

.v-input__slot {
    margin: 0 !important;
}

.leaflet-top.leaflet-left {
    bottom: 0 !important;
    right: 0 !important;
    left: auto !important;
    top: auto !important;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
    margin: 0 !important;
    margin-bottom: 116px !important;
    margin-right: 26px !important;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
    border-radius: 0.45rem;
    overflow: hidden;
}

slide-over-to-right {
    transform: translateX(0px);
}

.slide-over-to-left {
    transform: translateX(-276px);
}

.v-list-item__title,
.v-list-item__title.route__title {
    white-space: break-spaces;
}

.v-expansion-panels .v-expansion-panel {
    background-color: #fff9;
    color: #65296c !important;
    font-weight: bold;
    margin: 8px 0;
    border-radius: 0.55rem;
}

.v-expansion-panel-header {
    padding: 8px;
}

.v-expansion-panels
    .v-expansion-panel-header
    .v-expansion-panel-header__icon
    .v-icon {
    color: rgba(101 41 108 / 0.54) !important;
}

.v-expansion-panel-content__wrap {
    /* padding: 8px 12px; */
    padding: 0;
    border-radius: 0.55rem;
    margin: 8px;
}

/* .v-expansion-panel-content__wrap:hover { */
.v-list-item.v-list-item--link.theme--light:hover {
    background: #8a2be21c;
}

.v-application--is-ltr .v-expansion-panel-header {
    background: #f1f1f1;
    border-bottom-left-radius: 0.55rem;
    border-bottom-right-radius: 0.55rem;
}

.v-list-item__icon {
    margin-right: 8px !important;
}

.v-list-item.v-list-item--link.theme--light.active {
    background: aquamarine;
}

#stop__icon {
    margin-right: 0px !important;
}

.v-navigation-drawer .menu-icon {
    margin: 0;
    margin-left: 0.6rem;
    width: min-content;
    padding: 0;
    display: block;
    width: fit-content;
    padding: 3px;
    border-radius: 0.55rem;
}

.v-navigation-drawer .menu-icon:hover {
    background: #d8d8d8c7;
    cursor: pointer;
}

.v-navigation-drawer--mini-variant .menu-icon {
    display: block;
    text-align: center;
    /* margin: auto; */
    transition: all ease-in-out 0.4s;
}

.v-navigation-drawer--is-mouseover .menu-icon {
    /* margin-left: auto; */
    transition: all ease-in-out 0.4s;
}

.v-navigation-drawer {
    height: -webkit-fill-available !important;
    z-index: 10;
    margin: 20px;
    border-radius: 0.55rem;
    background-color: rgba(255, 255, 255, 0.75) !important;
    backdrop-filter: blur(20px) saturate(180%);
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
    transition: all ease-in-out 0.25s;
}

.v-navigation-drawer.marker-info-panel {
    z-index: 7;
    padding-left: 82px;
    margin: 7px;
    height: -webkit-fill-available !important;
    width: 356px !important;
    transition: all ease-in-out 0.25s;
}

.v-navigation-drawer.marker-info-panel.nav-is-open {
    padding-left: 282px;
    width: 556px !important;
    transition: all ease-in-out 0.25s;
}

.v-navigation-drawer--mini-variant .footer {
    display: none;
    text-align: justify !important;
    line-height: 1 !important;
    transition: display ease 0.05s;
}

.v-navigation-drawer--is-mouse-over .footer {
    display: block;
    transition: display ease 0.05s;
}

.v-list-item__icon span {
    margin: auto;
}

.v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child),
.v-list--nav .v-list-item--dense:not(:last-child):not(:only-child),
.v-list--rounded.v-list--dense .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item--dense:not(:last-child):not(:only-child) {
    margin: 4px 0;
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #82358bc7 !important;
    text-shadow: black 0 0;
}

.marker-banner-img {
    border-top-right-radius: 0.55rem;
    border-bottom-left-radius: 0.55rem;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}

.v-list--dense .v-list-item .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list-item--dense .v-list-item__title {
    font-weight: bold !important;
    color: #5d5d5dad;
    margin-left: 12px;
}

.v-list-item__content {
    display: block;
}

.v-image__image {
    image-rendering: -webkit-optimize-contrast;
}

#detailsContent {
    color: #252525;
    margin: 5px;
}

#detailsContentCloseButton {
    position: absolute;
    top: 20px;
    left: 355px;
    z-index: 20;
    transform: translateX(-355px);
    transition: all ease-in-out 0.25s;
}

.v-navigation-drawer.marker-info-panel + #detailsContentCloseButton {
    transform: translateX(0px);
    transition: all ease-in-out 0.25s;
}

.v-navigation-drawer.marker-info-panel.nav-is-open
    + #detailsContentCloseButton {
    left: 555px;
    transition: all ease-in-out 0.25s;
}

.v-step[x-placement^="right"] {
    left: 330px !important;
    position: fixed;
}

.v-step {
    background: #efefefb8 !important;
    backdrop-filter: blur(20px) saturate(180%);
    color: #252525 !important;
    border-radius: 0.55rem !important;
}

.v-step__button {
    background: transparent;
    border: 0.15rem solid #6b3b737a !important;
    border-radius: 0.55rem !important;
    color: #6b3b73de !important;
    font-size: 0.8rem !important;
    font-weight: 900 !important;
}

.v-step__button:hover {
    background: transparent !important;
    border: 0.15rem solid #6b3b73 !important;
    color: #6b3b73 !important;
    transition: border ease-in-out 0.15s;
}

.v-step__header {
    background-color: #92929200 !important;
    backdrop-filter: blur(20px) saturate(180%) !important;
    border-top-left-radius: 0.55rem !important;
    border-top-right-radius: 0.55rem !important;
}

.v-step__arrow--dark {
    border-color: #e4e4e3;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #82358bc7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #82358b;
}

@media (min-width: 768px) {
    .mdhidden {
        display: none;
    }
}
